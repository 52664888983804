<script setup>
import BreezeAuthenticatedLayout from '@/Layouts/Authenticated.vue';
import Heading_H1 from "@/UI/Text/Heading_H1.vue";
import {Link} from '@inertiajs/vue3'

let prop = defineProps(['data'])


function convert_Date(date){
    const event = new Date(date);
    const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };

    return event.toLocaleDateString('en-GB', options)
}

function convert_Time(date){
    const event = new Date(date);
    return event.toLocaleTimeString('en-GB',{ hour: "2-digit", minute: "2-digit",  hour12: true })
}
</script>

<template>
    <BreezeAuthenticatedLayout>
        <div class="pb-24 mx-4 overflow-x-hidden">
            <Link :href="route('user.settings.index')" class="flex flex-wrap items-center justify-start w-full mt-4 ">
                <svg width="32" height="32" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_2440_1962)">
                        <rect class="fill-[#232F80] dark:fill-[#2F45C5]" x="40.1875" y="40.6123" width="40" height="40" rx="20" transform="rotate(180 40.1875 40.6123)"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M23.0362 26.2608C23.5049 25.7922 23.5049 25.0324 23.0362 24.5638L19.0848 20.6123L23.0362 16.6608C23.5049 16.1922 23.5049 15.4324 23.0362 14.9638C22.5676 14.4951 21.8078 14.4951 21.3392 14.9638L16.5392 19.7638C16.0705 20.2324 16.0705 20.9922 16.5392 21.4608L21.3392 26.2608C21.8078 26.7295 22.5676 26.7295 23.0362 26.2608Z" fill="white"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_2440_1962">
                            <rect width="40" height="40" fill="white" transform="translate(0.1875 0.612305)"/>
                        </clipPath>
                    </defs>
                </svg>
                <span class="font-medium montserrat ml-3 uppercase text-t_purple-700 text-[13px] dark:text-white font-bold">Back - </span>
                <span  class=" montserrat ml-3 uppercase text-t_purple-700 text-[13px] dark:text-white">
                    Me
                </span>
            </Link>
            <div class="flex flex-wrap mt-12 ">
                <div class="w-full text-t_warms-400 uppercase montserrat font-medium text-[12px] ml-4 mb-3">
                    scan
                </div>
                <Heading_H1 :type="true">#{{prop.data.id}}</Heading_H1>
                <div class="w-full flex flex-wrap gap-y-[12px] mt-[24px] font-fira text-[18px] leading-[130%] font-normal">
                    <div class="w-4/12 dark:text-t_white-200 text-t_blue-700">
                        QR ID
                    </div>
                    <div class="w-8/12 font-extrabold dark:text-t_white-200 text-t_black-800">
                        {{prop.data.trap.qr_id ?? ''}}
                    </div>
                    <div class="w-4/12 dark:text-t_white-200 text-t_blue-700">
                        Trap ID
                    </div>
                    <div class="w-8/12 font-medium dark:text-t_white-200 text-t_black-800">
                        {{prop.data.trap.name ?? ''}}
                    </div>
                    <div class="w-4/12 dark:text-t_white-200 text-t_blue-700">
                        Scanned by
                    </div>
                    <div class="w-8/12 font-medium dark:text-t_white-200 text-t_black-800">
                        {{prop.data?.user?.name ?? 'Anon'}}
                    </div>
                    <div class="w-4/12 dark:text-t_white-200 text-t_blue-700">
                        Scan date
                    </div>
                    <div class="w-8/12 font-medium dark:text-t_white-200 text-t_black-800">
                        {{convert_Date(prop.data.time)}}
                    </div>
                    <div class="w-4/12 dark:text-t_white-200 text-t_blue-700">
                        Scan time
                    </div>
                    <div class="w-8/12 font-medium dark:text-t_white-200 text-t_black-800">
                        {{convert_Time(prop.data.time)}}
                    </div>
                    <div class="w-4/12 dark:text-t_white-200 text-t_blue-700">
                        Status
                    </div>
                    <div class="w-8/12 font-medium dark:text-t_white-200 text-t_black-800">
                        {{prop.data.status ?? ''}}
                    </div>
                    <div class="w-4/12 dark:text-t_white-200 text-t_blue-700">
                        Species
                    </div>
                    <div class="w-8/12 font-medium " :class="{'dark:text-t_white-200 text-t_black-800': prop.data.species_caught === 'None', 'text-t_warms-500': prop.data.species_caught !== 'None'}">
                        {{prop.data.species_caught ?? ''}}
                    </div>
                    <div class="w-4/12 dark:text-t_white-200 text-t_blue-700">
                        Rebaited
                    </div>
                    <div class="w-8/12 font-medium dark:text-t_white-200 text-t_black-800">
                        {{prop.data.rebaited === 0 ? 'No' : 'Yes'}}
                    </div>
                    <div class="w-4/12 dark:text-t_white-200 text-t_blue-700">
                        Bait type
                    </div>
                    <div class="w-8/12 font-medium dark:text-t_white-200 text-t_black-800">
                        {{prop.data.bait_type ?? ''}}
                    </div>
                    <div class="w-4/12 dark:text-t_white-200 text-t_blue-700">
                        Condition
                    </div>
                    <div class="w-8/12 font-medium dark:text-t_white-200 text-t_black-800">
                        {{prop.data.trap_condition ?? ''}}
                    </div>
                    <div class="w-4/12 dark:text-t_white-200 text-t_blue-700">
                        Notes
                    </div>
                    <div class="w-8/12 font-medium dark:text-t_white-200 text-t_black-800">
                        {{prop.data.note ?? ''}}
                    </div>
                </div>


            </div>
        </div>

    </BreezeAuthenticatedLayout>
</template>

<style scoped>

</style>
