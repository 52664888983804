<template>
    <BreezeAuthenticatedLayout>
        <div class="flex flex-wrap mt-[15px] mb-[8px] px-[24px]">
            <Link :href="route('user.settings.trap.view',trap.id)" class="flex flex-wrap items-center justify-start w-full ">
                <BlueCircle/>
                <span class="font-bold montserrat ml-3 uppercase text-t_purple-700 text-[13px] dark:text-white">Back - </span>
                <span  class=" montserrat ml-3 uppercase text-t_purple-700 text-[13px] dark:text-white">
                {{trap.name}}
              </span>
            </Link>
            <div class="w-full mt-[16px]">
                <span class="text-[24px] mb-[40px] mfont-bold text-t_black-800 dark:text-t_white-200 font-fira font-semibold">
                Update trap location
                </span>
            </div>
            <p class="w-full my-[24px] font-fira dark:text-t_white-300 text-t_black-80 text-[18px]">
                Hold your phone against the trap for 5 seconds before pressing the UPDATE LOCATION button for the most accurate location result.
                <br>
                <br>
                Altitude is saved in addition to Latitude and Longitude.
            </p>
            <div class="mt-[16px] w-full">
                <primary  @click="getLocation">
                    UPDATE LOCATION
                </primary>
            </div>
        </div>
    </BreezeAuthenticatedLayout>
</template>

<script>
import BreezeAuthenticatedLayout from '@/Layouts/Authenticated.vue';
import { Link, useForm } from '@inertiajs/vue3'
import Primary from "@/UI/Buttons/Primary.vue";
import BlueCircle from "@/Components/SVG/BlueCircle.vue";

export default {
    components:{
        Link,
        BreezeAuthenticatedLayout,
        Primary,
        BlueCircle
    },
    name: "EditTrapLocation",
    props:{
        trap:Object,
    },
    data() {
        return {
            form: useForm({
                latitude: '',
                longitude: '',
                altitude: '',
            })
        }
    },
    mounted() {
    },
    methods: {
        submit(){
            this.form.put(route('user.settings.trap.update_location', this.trap.id))
        },
        getLocation() {
            let app = this
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    position => {
                        app.form.latitude = position.coords.latitude
                        app.form.longitude = position.coords.longitude
                        app.form.altitude = position.coords.altitude
                        app.submit()
                    },
                    error => {
                        console.log(error.message)
                    }
                )
            } else {
                console.log("Geolocation is not supported by this browser.")
            }
        }
    }
}
</script>
<style scoped>

</style>
