<script setup>
import Primary from "@/UI/Buttons/Primary.vue";
import { reactive } from 'vue'
import Heading_H1 from "@/UI/Text/Heading_H1.vue";

const emit = defineEmits(['update-location']);

const state = reactive({
    latitude: null,
    longitude: null
})

function getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            emit('update-location', position)
          },
          error => {
            console.log(error.message)
          }
        )
      } else {
        console.log("Geolocation is not supported by this browser.")
      }
    }
</script>
<template>
    <div class="w-full flex flex-wrap">
        <Heading_H1 class="mb-[32px]">
            Access phone GPS to set trap location
        </Heading_H1>
        <span class="">
        </span>
        <p class="w-full mb-[32px] dark:text-t_white-200 text-t_black-800 font-normal font-fira text-[18px] leading-[140%] tracking-[1%]">
            Hold your phone against the trap for 5 seconds before pressing the SAVE LOCATION button for the most accurate location result.
            <br>
            <br>
            Altitude is saved in addition to Latitude and Longitude.
        </p>
        <primary  @click="getLocation">
            Save location
        </primary>
    </div>
</template>
