<script setup>
import Panel from "@/Components/Panel.vue"
import Heading_H1 from "@/UI/Text/Heading_H1.vue";

const emit = defineEmits(['update-type']);

</script>
<template>
    <div class="w-full flex flex-wrap">
        <Heading_H1 class="mb-[32px]">
            What type of trap is this?
        </Heading_H1>
        <panel @click='emit("update-type", 1)' text="Backyard trap" small_text="(Private) Location will be hidden from others.">
            <template #svg>
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.51416 14.3992L4.18083 11.7325M4.18083 11.7325L13.5142 2.39917L22.8475 11.7325M4.18083 11.7325V25.0658C4.18083 25.8022 4.77778 26.3992 5.51416 26.3992H9.51416M22.8475 11.7325L25.5142 14.3992M22.8475 11.7325V25.0658C22.8475 25.8022 22.2505 26.3992 21.5142 26.3992H17.5142M9.51416 26.3992C10.2505 26.3992 10.8475 25.8022 10.8475 25.0658V19.7325C10.8475 18.9961 11.4444 18.3992 12.1808 18.3992H14.8475C15.5839 18.3992 16.1808 18.9961 16.1808 19.7325V25.0658C16.1808 25.8022 16.7778 26.3992 17.5142 26.3992M9.51416 26.3992H17.5142" stroke="#00A376" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </template>
        </panel>
        <panel @click='emit("update-type", 0)' text="Trap line trap" small_text="(Public) Location will be visible to others so they can find it.">
            <template #svg>
                <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M29.0586 10.3992C29.0586 11.7742 27.9336 12.8992 26.5586 12.8992C26.3336 12.8992 26.1211 12.8742 25.9211 12.8117L21.4711 17.2492C21.5336 17.4492 21.5586 17.6742 21.5586 17.8992C21.5586 19.2742 20.4336 20.3992 19.0586 20.3992C17.6836 20.3992 16.5586 19.2742 16.5586 17.8992C16.5586 17.6742 16.5836 17.4492 16.6461 17.2492L13.4586 14.0617C13.2586 14.1242 13.0336 14.1492 12.8086 14.1492C12.5836 14.1492 12.3586 14.1242 12.1586 14.0617L6.47109 19.7617C6.53359 19.9617 6.55859 20.1742 6.55859 20.3992C6.55859 21.7742 5.43359 22.8992 4.05859 22.8992C2.68359 22.8992 1.55859 21.7742 1.55859 20.3992C1.55859 19.0242 2.68359 17.8992 4.05859 17.8992C4.28359 17.8992 4.49609 17.9242 4.69609 17.9867L10.3961 12.2992C10.3336 12.0992 10.3086 11.8742 10.3086 11.6492C10.3086 10.2742 11.4336 9.14917 12.8086 9.14917C14.1836 9.14917 15.3086 10.2742 15.3086 11.6492C15.3086 11.8742 15.2836 12.0992 15.2211 12.2992L18.4086 15.4867C18.6086 15.4242 18.8336 15.3992 19.0586 15.3992C19.2836 15.3992 19.5086 15.4242 19.7086 15.4867L24.1461 11.0367C24.0836 10.8367 24.0586 10.6242 24.0586 10.3992C24.0586 9.02417 25.1836 7.89917 26.5586 7.89917C27.9336 7.89917 29.0586 9.02417 29.0586 10.3992Z" fill="#D44944"/>
                </svg>
            </template>
        </panel>
    </div>
</template>
